body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F7;
}
html, body ,div#root{ height: 100%;}

/*
*
* CSS Index 
* 1. General Paddings, Margins and Alignments
* 2. Inputs
* 3. Fonts, Fonts Colors and Sizes
* 4. Backgrounds
* 5. Overrides
* 6. General Elements
* 7. Responsive
*/


/*
* -------------------------------------------------------
* |  1. General Paddings, Margins and Alignments         |
* -------------------------------------------------------
*/
.m-0 { margin: 0 !important;}
.m-lr-auto { margin:0 auto !important;}
.m-lr-2 { margin-left: 2% !important; margin-right: 2% !important;}
.m-lr-5 { margin-left: 5% !important; margin-right: 5% !important;}
.m-lr-10 { margin-left:10% !important; margin-right: 10% !important; }
.m-lr-15 { margin-left:15% !important; margin-right: 15% !important; }
.m-tb-2 { margin-top: 2% !important; margin-bottom: 2% !important;}
.m-tb-5 { margin-top:5% !important; margin-bottom: 5% !important;}
.m-tb-10 { margin-top: 10% ; margin-bottom: 10%; }
.m-t-2 { margin-top: 2% !important;}
.m-t-5 { margin-top: 5% !important;}
.m-b-2 { margin-bottom: 2% !important;}
.p-lr-2 {padding-left: 2%; padding-right: 2%;}
.p-lr-5 { padding-left: 5% !important; padding-right: 5% !important;}
.p-lr-10 { padding-left: 10% !important; padding-right: 10% !important; }
.p-lr-15 { padding-left: 15%; padding-right: 15%; }
.p-tb-2 { padding-top: 2%; padding-bottom: 2%;}
.p-tb-5 { padding-top: 5%; padding-bottom: 5%;}
.p-tb-10 { padding-top: 10%; padding-bottom: 10%;}
.p-b-5 { padding-bottom: 5% !important;}
.p-t-2 { padding-top: 2% !important;}
.p-10-x { padding: 10px}

.flex {display: flex !important;}
.relative { position: relative;}
.inlineBlock { display: inline-block;}
.inlineFlex { display: inline-flex;}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}
.absolute{
  position: absolute !important;
}

div#editWarehouse button.editPreviousOrder.absolute {
  right: 0;
  top: -30px;
}

.notificationText { 
  width: 300px !important;
  font-family: 'Work-Sans' !important;
  display: block !important;
}
#user-menu ul{
padding: 0 ;
}

.userMenuDiv{
  min-width: 300px;
}
.notification-header {
  width: 70%;
  text-align: right;
}

.task-sidebar {
  margin-left: 20%;
  margin-top: 3%;
}
.MuiExpansionPanelDetails-root.contentDropdownTasks {
  flex-direction: column;
}
#taskProductHeader #taskIcon { position: relative; top:5px}
.mainHeader {
  width: 33%;
  display: inline-block;
}
/*
* ------------------------------------------
* | 2. Inputs                              |
* ------------------------------------------
*/
.loginForm {
 display: block !important;
}
.loginInput {
  background-color: #ffffff;
}
.loginInput fieldset{
  border-radius:0;
}
.errorClass fieldset{
  border: 2px solid #db496d;
}
.errorClassSelect {
  border: 2px solid #db496d !important;
}
.error {
  color : #c23d4b;
}
.loginForm div.MuiFormControl-root , .billingButton{
  width: 100%;
}
div.nonEditableInfo .reorderInput {
  width: 45%;
  margin-right: 5%;
}
#productsDashboard .loadMore{
    display: block ;
    width: 20%;
    text-align: center;
    margin: auto;
    cursor: pointer;
}
.yellowSubmit, .yellowButtonTasks , .billingButton button{
  background-color: #FEC72F;
}
button.actionsConfirmation {
  font-family: 'Rift';
  font-size: 20px;
  background-color: #fec72f;
  border-radius: 0;
}
.successButtonTasks {
  background-color: #4CAF50;
}
.successButtonTasks input, .successButtonTasks svg {
  color:#ffffff;
  font-family : 'Rift' !important;
  font-weight: bold;
  font-size: 1.2em;
}
.yellowSubmit fieldset, .yellowButtonTasks fieldset, .successButtonTasks{
  border-radius: 0;
  border:0;
}
.yellowSubmit input , #addTodo, .billingButton button{
  color: #000000;
  font-family: 'Rift' !important;
  font-weight: bold;
  font-size: 1.5em;
  cursor: pointer;
}
.yellowButtonTasks input{
  color: #000000;
  font-family: 'Rift' !important;
  font-weight: bold;
  font-size: 1.3em;
  cursor: pointer;
  padding: 10px;
}
.logOutAdmin{
  color: #000000;
  font-family: 'Rift' !important;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  padding: 2px 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
}
.logOutAdmin:hover{
  background-color: #FEC72F;
}
.logoContainer {
  margin-top: 15%;
  margin-bottom:10%;
  text-align: center;
}
.selectForm { 
  width: 100%;
  height: 56px; 
  border: 1px solid #ccc;
  padding: 10px;
}
.selectFormAdmin{
  width: 93%;
  height: 56px; 
  border: 1px solid #ccc;
  padding: 10px;
}
.tasksDiv, #sidebarAdmin{
  border-right: 1px solid #ccc;
}
#sidebarAdmin { height: 100%;}
div.productsInput, div.productsInput fieldset, div.productsInput.selectForm {
  height:56px;
}
.productsInput fieldset { border-radius: 0;}
div#targetPriceForm{
  width: 228px;
  margin-right: 20px;
}
.split-form {
  display: flex;
  justify-content: space-between;
}
.stripeCheckout .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 200px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  font-family: 'Work-Sans';
}
.billingStatements .downloadInvoice {
  height: 19px;
  vertical-align: bottom;
}
.bills div.yellowSubmit.nextArrow input { 
  background-image: url('../src/assets/img/backIcon2.png');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
}
.helpmeSource {
  min-width: 500px;
}

button.MuiButtonBase-root.editPreviousOrder {
  padding: 0;
  width: 40px;
  min-width: 40px;
}
.nonEditableInfo.m-t-2 {
  width: 50%;
  display: inline-block;
}
#editingShipping {
  width: 50%;
  display: inline-block;
}
.extrasBilling {
  border: 1px solid #ccc;
  margin-top: 2%;
  padding: 10px;
}
#billingForm button {
  width: 50%;
  margin: 2% auto;
  display: block;
}
/*
* ---------------------------------------------
* | 3. Fonts, Fonts Colors and Sizes          |
* ----------------------------------------------
*/
@font-face {
  font-family: 'Work-Sans';
  src: url('../src/assets/fonts/WorkSans-Regular.ttf');
  font-weight:400;
}
@font-face {
  font-family: 'Work-Sans Bold';
  src: url('../src/assets/fonts/WorkSans-Bold.ttf');
  font-weight:700;
}
@font-face {
  font-family: 'Rift';
  src: url('../src/assets/fonts/RiftSoft-Medium.otf');
  font-weight: 500;
}
p { font-family: 'Work-Sans', sans-serif}
h1,h2, h3 { 
  font-family: 'Rift';
}
h3 { font-size: 1.5em;}

input { font-family: 'Work-Sans' !important; }
.bold { font-weight: bold;}
.forgot p  {
  color : #3BA3FF;
  font-family: 'Work-Sans';
  cursor: pointer;
}
.rememberme + span { font-family: 'Work-Sans' !important;}
.account {
  color: #9998AF;
}
.account a, #goBackDashboard   {
  text-decoration: none;
}
a.link{
  text-decoration: none;
  color: inherit;
}
.account span, .cancel{
  color : #ff6700;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Work-Sans';
}
.regularTitle {
  color: #000000 !important;
  font-weight: bold;
  font-size: 1.5em !important;
  font-family: 'Rift' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.notificationsText{
  font-family: 'Work-Sans';
}
.workSans{
  font-family: 'Work-Sans' !important;
}
.rift { font-family: 'Rift' !important; }
.userAvatar.workSans { 
  color : #000; 
  letter-spacing: 0;
  font-weight: bold;}

#notificationBell, .whiteText {
  color : #ffffff;
}
div#checkboxContainer span.MuiFormControlLabel-label {
  font-family: 'Work-Sans';
}
div#checkboxContainer div.MuiSelect-root {
  font-family: 'Work-Sans';
} 

textarea, .loginForm label span, #select-targetprice {
  font-family: 'Work-Sans' !important;
}

#taskProductHeader p { font-size: 1.5em;}
.capitalize { text-transform: capitalize;}
li.inlineBlock.specsFiles ,li.inlineBlock.specsFiles a, #activityDetails span.previewName,  
#descriptionTask span.previewName, #product-specs  span.previewName, div#descriptionTask a.link{
  color: #3BA3FF;
  text-decoration: none;
}
#tableDetails div.row{
  margin-bottom: 2px;
}
#tableDetails div.keys{
  border-spacing: 2px;
  background-color: #BBBBBB;
  border-radius: 5px;
  max-width: 150px;
  width: 150px;
  word-break: break-word;
  padding: 5px;
}
#tableDetails div.values{
  border-spacing: 2px;
  background-color: #F1F1F1;
  border-radius: 5px;
  word-break: break-word;
  padding: 5px;
  width: 100%;
  margin-left: 2px;
}

li.inlineBlock.specsFiles svg {
  padding-left: 5px;
  height: 20px;
}
#activityDetails a svg , #descriptionTask a svg{
  height: 15px;
  width: 18px;}
#billingForm{
  min-height: 150px;
  border: 1px solid #cccccc;
  position: relative;
}
div.billingColumn {
    width: 20%;
    display: inline-block;
}
div.billingColumnSM {
  width: 10%;
  display: inline-block;
}
.columnExtrBill {
  width: 33%;
  display: inline-block;
}
div.billingColumn h4, div.billingColumnSM h4 {
  padding:10px;
  margin:0;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.selectBilling {
  margin: 10px 5%;
  width: 90%;
  height: 35px;
}
.billingColumn input, .billingColumnSM input {
  width: 90%;
  margin-top: 10px;
  height: 30px;
  text-align: right
}
.editAdminUser{
  width: 90%;
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  word-break: break-all;
}
.editAdminUser button.editPreviousOrder {
  position: absolute;
  right: -40px;
}
/*
* -----------------------------------------------
* | 4. Backgrounds                               |
* -----------------------------------------------
*/
.darkBg {
  background-color: #212224;
}
.headerBg{
  background-color: #000000;
}
.whiteBg {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
}
.yellowDotsBg{
  background-image: url(/static/media/grunge-01.dd85818b.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 35%;
  background-position-y: top;
}
.avatarBg {
  background-color: #e74c3c  !important;
  font-size: 1em !important;
  font-family: Work-Sans !important;
}
.userAvatar{
  background-color: #fec72f !important;
}
.MuiAvatar-root.avatarTasksActivity{
  background-color: #ff5722;
  width: 25px;
  height: 25px;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Work-Sans';
  margin: 0;
  display:inline-flex;
}

#activityDetails div.comment {
  background-color: #ffffff;
  font-family: 'Work-Sans';
}
#full-notifications.grayBg .MuiDialog-paperFullScreen, #billing.grayBg .MuiDialog-paper {
  background-color:  #F5F5F7 !important;
}
#full-tasks.grayBg .MuiDialog-paperFullScreen , #full-filter.grayBg .MuiDialog-paperFullScreen, #full-specs.grayBg .MuiDialog-paper {
  background-color:  #F5F5F7 !important;
}
#user-menu .userAvatar {
  width: 80px;
  height: 80px;
  border: 4px solid #fff;
  font-size: 1.7em;
}
.contentStatusDesign::after {
  background-color: #ffde84;
}
.contentStatusPre::after {
  background-color: #fec72f;
}
.contentStatusProd::after {
  background-color: #ec9172;
}
.contentStatusShipping::after {
  background-color: #f9612e;
}
.contentStatusFulfilled::after {
  background-color: #0dd4aa;
}
div.statusDesign { background-color: #ffde84}
div.statusPre { background-color: #FEC72F}
div.statusProd { background-color: #ec9172}
div.statusShipping { background-color: #f9612e}
div.statusFulfilled { background-color: #0DD4AA}
.disabledBg {
  background-color:  #F5F5F7 !important;
}
ul#progressStatus li.stepDesign::before {
  background-color: #ffde84;
  border: 2px solid #ffde84;
  background-image: url('../src/assets/img/Path.png');
  background-repeat: no-repeat;
  background-position-x: center;
    background-position-y: center;
}
#progressStatus li.stepDesign:after {
  background-color: #ffde84;
}
ul#progressStatus li.stepPre::before {
  background-color: #fec72f;
  border: 2px solid #fec72f;
  background-image: url('../src/assets/img/Path.png');
  background-repeat: no-repeat;
  background-position-x: center;
    background-position-y: center;
}
#progressStatus li.stepPre:after {
  background-color: 
  #fec72f;
}
ul#progressStatus li.stepProd::before {
  background-color: #ec9172;
  border: 2px solid #ec9172;
  background-image: url('../src/assets/img/Path.png');
  background-repeat: no-repeat;
  background-position-x: center;
    background-position-y: center;
}
#progressStatus li.stepProd:after {
  background-color: #ec9172;
}
ul#progressStatus li.stepShipping::before {
  background-color: #f9612e;
  border: 2px solid #f9612e;
  background-image: url('../src/assets/img/Path.png');
  background-repeat: no-repeat;
  background-position-x: center;
    background-position-y: center;
}
#progressStatus li.stepShipping:after {
  background-color: #f9612e;
}
ul#progressStatus li.stepFulf::before {
  background-color: #0dd4aa;
  border: 2px solid #0dd4aa;
  background-image: url('../src/assets/img/Path.png');
  background-repeat: no-repeat;
  background-position-x: center;
    background-position-y: center;
}
#progressStatus li.stepFulf:after {
  background-color: #0dd4aa;
  ;
}
.previewFilesBg{
  position: fixed;
  background: #0000008a;
  z-index: 3;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.previewFileContainer {
  height: auto;
  box-sizing: border-box;
  padding: 48px 24px 112px;
}
.imagePreview {
  max-width: 100%;
  display: block;
  margin: 5% auto;
}
.defaultPreview {
  width: 50px;
    height: 50px;
    background-color: #efefef;
    color: #888888;
    text-transform: uppercase;
}
div#menu-notifications #menu-list-grow ul {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
/*
* ------------------------------------------------
* | 5. Overrides                                 |
* ------------------------------------------------
*/
.Toastify__close-button {
  color: #cccccc !important;
  font-weight: 300;
  font-size: 20px;
}
.Toastify__toast--error {
  border-top: 5px solid #e74c3c !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  font-family: 'Work-Sans';
}
.Toastify__toast--success {
  border-top:5px solid #0DD4AA !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  font-family: 'Work-Sans';
}
.Toastify__toast--info {
  background-color: #ffffff !important;
  color: #000 !important;
  border-top: 5px solid #5ac8fa !important;
  font-family: 'Work-Sans';
}
.Toastify__toast--warning {
  border-top: 5px solid #FEC72F !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  font-family: 'Work-Sans';
}

#adminTabs .MuiTabs-indicator {
  display: none;
}
.productProgress .MuiLinearProgress-root { height: 8px !important; border-radius: 8px;}

.MuiExpansionPanel-root.Mui-expanded {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.MuiButtonBase-root.MuiExpansionPanelSummary-root.Mui-expanded {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
div#full-specs .MuiDialog-paper {
  min-width: 300px;
  width:100%;
}
div#product-specs div.sectionSpecs {
  border-bottom: 1px solid #ccc;
  align-items: center;
  display: flex;
  margin-top: 2%;
  margin-bottom: 2%;
}
div#product-specs div.sectionSpecs h4 {
  display: inline-block;
  min-width: 150px;
  width: 150px;
}
button.editPreviousOrder.cancel svg {
  color: #ff6700;
}
button.editPreviousOrder svg {
  color: #bdbdbd;
}
#accountModal .MuiPaper-root.MuiDialog-paperWidthMd, 
#taskDetailsMOdal .MuiPaper-root.MuiDialog-paperWidthMd,
#flowsStartModals  .MuiPaper-root.MuiDialog-paperWidthMd, 
#billing  .MuiPaper-root.MuiDialog-paperWidthMd,
#forgotPassword .MuiPaper-root.MuiDialog-paperWidthMd, 
#resetPassword .MuiPaper-root.MuiDialog-paperWidthMd ,
#newTaskModal .MuiPaper-root.MuiDialog-paperWidthMd,
#editTaskModal .MuiPaper-root.MuiDialog-paperWidthMd{
  width: 66%;
}
#adminTabs .MuiTabs-scroller .MuiTabs-flexContainer{
  flex-direction: column;
}
div#adminTabs button {
  width: 100%;
  min-width: 100%;
}
div#adminTabs button.Mui-selected {
  border-right: 3px solid #f1c40f ;
}
/*
* -------------------------------------------------
*|  6. General Elements                            |
* -------------------------------------------------
*/
.cursorPointer { cursor: pointer;}
div#menu-filters {
  z-index: 2;
  min-width: 250px;
}
#checkboxContainer label {
  display: block;
}
div#menu-notifications , div#menu-dots{
  z-index: 3;
  left: -20px !important;
}
.contentStatusDesign::after , .contentStatusPre::after, .contentStatusProd::after ,
.contentStatusShipping::after, .contentStatusFulfilled::after  {
  content: '';
  height: 7px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
}

button.cancelUserPopup {
  position: absolute;
  left: 0;
}
button.cancelUserPopup svg{
 color:#ffffff;
}
.searchTasks {
  width: 90%;
  margin-right: 10%;
}
.searchTasks fieldset { border-radius: 0; }
ul.taskList {
  list-style: none;
  border-top: 1px solid #ccc;
  position: relative;
  max-height: 550px;
  height: 550px;
  overflow-y: auto;
}
ul.taskList::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

ul.taskList::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
}

ul.taskList::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffde84;;
}
.taskList li {
  padding-right: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-bottom: 1px solid #ccc;
}
.taskList li:hover  p{
  color: #3BA3FF;
  cursor: pointer;
}
.taskList li::before{
  content:  url('../src/assets/img/litask.png');
  position: absolute;
  left: 0;
}
.taskList li:hover::before{
  content:  url('../src/assets/img/litask_hover.png');

}
.taskList li.completedTaskSidebar::before{
  content:  url('../src/assets/img/Checked.png');
  position: absolute;
  left: 0;
}
li.completedTaskProduct::before {
  content: url('../src/assets/img/Checked.png');
  position: absolute;
  left: 0;
}
li.notificationText  {
  border-bottom: 1px solid #ccc;
}
.taskList li:last-child, li.notificationText:last-child{
  border-bottom: none;
}
.taskStatus {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
#moreTasks svg { position: relative; top: 5px;}

button.newButton { background-color: #fff;
  border-radius: 0;
  border: 1px solid #Ccc;
  font-size: 1.2em;
  font-weight: bold;
}
button.filterButton {
  
  padding: 0;
  width: 25px;
  min-width: 25px;
}
#productsDashboard, #tasksOnProduct, #productsReviewDashboard {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: #ccc 5px 5px 10px;
}
#productsDashboard li, #fulfilledDashboard li, #completedTasksOnProduct li, #productsReviewDashboard li{
  list-style: none;
  border-bottom: 1px solid #ccc;
}
div.activeChanged  {
  transform: scale(1.2);
  box-shadow: 0px 4px 10px #fff283 !important;
}

#productsDashboard li:last-child, #fulfilledDashboard li:last-child, #completedTasksOnProduct li:last-child,
#productsReviewDashboard li:last-child {
  border-bottom: none !important;
}
.productImage {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 15px 0;
}
.productInfo {
  width: 70%;
}
.productProgress{ width: 10%; align-self: center;}
.productProgress span { position: absolute; left: -45px; top: -5px; font-family: 'Work-Sans'}
.productDots { align-self: center}
.singleStatusBar { height: 20px;}
.lifecycleInfo.workSans div {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
}
.lifecycleInfo span { 
  font-weight: bold; 
  padding-left: 5px;
  padding-right: 5px;
}
.productInfo a {
  text-decoration: none;
  color: inherit;
}
.lifecycleInfo p {
  margin: 5px 0;
  font-size: 0.83em;
}
#lifecycleBar div.lifecycleInfo.flex {
  justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    width: 100px;
    padding: 8px 8px;
    box-shadow: 0px 4px 10px #ccc;
    transition: all 0.5s;
}
div#productLifecycleInfo, #goBackDashboard, .completedTaskProduct div.floatRight{
  align-items: center;
}
#lifecycleBar #containerStages {
  justify-content: space-between;
}
#fulfilledDashboard ul {
  border: 1px solid #ccc;
}
#fulfilledDashboard #fulfilledTitle { font-weight: bold;}
div#menu-category ul { max-height: 300px;}
p#newProductInfo::after {
  width: 50%;
  display: block;
  content: "";
  margin: 10px auto;
  border-bottom: 1px solid #ccc;
}
#productDetailsTargets , #warehouseDetails{ justify-content: space-between}
div#warehouseDetails div.loginForm {
  margin-right: 5px;
}
#productDesignContainer { min-height: 500px;}

ul#progressStatus li {
  width: 23%;
  display: inline-block;
  position: relative;
}
ul#progressStatus li:last-child {
  width: 0;
 
}
ul#progressStatus li::before {
  width: 20px;
  height: 20px;
  background-color: #f5f5f7;
  content: '';
  position: relative;
  display: block;
  border-radius: 50%;
  border: 2px solid #ccc;
}
#progressStatus li:after {
  width: 100%;
  height: 3px;
  content: '';
  position: absolute;
  background-color: #ccc;
  top: 10px;
  left: 0;
  z-index: -1;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-family: 'Work-Sans';
  font-size: 0.8em;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
#progressStatus li:hover .tooltiptext {
  visibility: visible;
}
#progressStatus li[title]:hover {
  font-family: 'Work-Sans';
}
#progressStatus li:last-child:after {
  content: none;
}
li.uploadsInput { padding: 0; }
p.repeaterFields{
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  display: inline-block;
  color: #9c9c9c;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-all;
}
a.linkMaking {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  display: inline-block;
  color: #3BA3FF;
  text-decoration: none;
  margin-top: 5px;
  margin-bottom: 0;
  word-break: break-all;
  font-family: 'Work-Sans';
} 
span.spaceSub {
  padding-left: 5%;
}
#startMakingInfo p {
  padding-left: 30px;
}
#startMakingInfo .loginForm label span {
  font-weight: bold;
}
#startMakingInfo div.loginForm {
  padding-bottom:20px;
}
label.MuiFormControlLabel-root.warehouseRadio {
  display: block;
  width: auto;
  margin: 2% 0;
}
#completedTasksOnProduct { position: relative;}
li.completedTaskProduct {
  padding-bottom: 1em;
}
li.completedTaskProduct p {
  text-decoration: line-through;
}
.commentsOnProduct span {
  margin-right: 5px;
}
.MuiExpansionPanelDetails-root.contentDropdownTasks li {
  list-style: none;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-left: 40px;
  position: relative;
}
span.assignedSquare {
  position: absolute;
  left: -40px;
  top: 15px;
}
.MuiExpansionPanelDetails-root.contentDropdownTasks li:last-child {
  border-bottom: 0
}
.billingStatements{
  width: 50%;
  display: inline-block;
}
.bills { border: 1px solid #ccc;}
div.detailsTaskTitle { 
  margin-top: 50px;
  border-bottom: 1px solid #ccc;
}
div.detailsTaskTitle #assignedto{
  color:#E91E63;;
}
span.taggedUser {
  color: #3BA3FF;
  font-weight: 700;
}
.detailsTaskTitle h3 img {
  margin-right: 5px;
}
div#filesAttachedComments div {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
p#assignedto span svg {
  margin-right: 5px;
}
#activityDetails{
  background-color: #f5f5f7;
}
#activityDetails h3 {
  border-bottom: 1px solid #ccc;
}

.comments-textarea__input:focus, .comments-textarea__input:active{
    outline: none;
    border: 2px solid #3fb9de !important;
  }
div#addComments{
    display: flex;
    margin: 2% 0;
}
section#send {
    border: 1px solid #ccc;
    margin: 0 2%;
    display: flex;
}
section#mentions {
  width: 90%;
  margin-left: 5%;
  position: relative;
}
button.attachFile.MuiButton-text {
  position: absolute;
  right: 5px;
  top: 15px;
  width: auto;
  padding: 0;
  min-width: auto;
}
button.attachFileTasks.MuiButton-text {
  position: absolute;
  right: 5px;
  bottom:  5px;
  width: auto;
  padding: 0;
  min-width: auto;
}
button.attachFile.MuiButton-text svg , button.attachFileTasks.MuiButton-text svg {
  transform: rotate(45deg);
}
input#fileComments, input#filesToAttach {
  width: 30px;
  position: absolute;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
div#todosItemsContainer {
  display: flex;
  align-items: center;
}
div#todosItemsContainer .todosItems {
  width: 90%;
}
.MuiAvatar-root.avatarAssignedTo {
  display: inline-flex;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  font-size: 1em;
  font-weight: 700;
}
.notificationCounter {
  background-color: #E91E63;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  font-family: 'Work-Sans';
  color: #ffffff;
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
}
div.loginForm .todosItems.loginInput {
  width: 90%;
}
.m-b-2.todosItemsText p {
  width: 86%;
}

/*
* ------------------------------------------------
* | 7. Responsive                                 |
* ------------------------------------------------
*/
@media (max-width:1279px) and (min-width:980px){
  .logoContainer{
    margin-top: 35%;
    margin-bottom: 15%;
  }
  #adminTabs button span.MuiTab-wrapper {  font-size: 0.8em !important; }
}

@media (max-width:979px) and (min-width:650px){
  .logoContainer{
    margin-top: 35%;
    margin-bottom: 15%;
  }

}
@media( max-width:979px) and (min-width:599px){
  .lifecycleInfo p {
    font-size: 11px;
  }
  .lifecycleInfo span {
    font-size: 0.7em;
    padding-left: 0;
}
#adminTabs button span.MuiTab-wrapper {  font-size: 0.8em !important; }
#lifecycleBar div.lifecycleInfo.flex {
  width: 60px;
  padding: 2px;
}
.lifecycleInfo.workSans.flex svg {
  width: 30px;
}
}

@media (max-width:803px) and (min-width: 651px ){
  .productImage {
    margin: 25px 0;
  }
}
@media (max-width:650px) and (min-width:426px){
 
  
  .logoContainer{
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .taskList p , .taskList div.account{ font-size: 0.8em}
}

@media (max-width:950px){
  #sidebarAdmin { height: auto;}
  #adminTabs .MuiTabs-scroller .MuiTabs-flexContainer + span { display: none;}
  table#usersListTable, #usersListTable  thead, #usersListTable tbody, #usersListTable th, 
  #usersListTable td, #usersListTable tr { 
		display: block; 
  }
  #usersListTable td { 
    padding-left: 50% !important;
     position: relative;
     word-break: break-all;
    }
  #usersListTable thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  #usersListTable td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
  #usersListTable td:nth-of-type(1):before { content: "Name"; }
  #usersListTable td:nth-of-type(2):before { content: "Last Name"; }
  #usersListTable td:nth-of-type(3):before { content: "Email"; }
  #usersListTable td:nth-of-type(4):before { content: "Role"; }
	#usersListTable td:nth-of-type(5):before { content: "Created Date"; }
	#usersListTable td:nth-of-type(6):before { content: "Updated Date"; }


}

@media (max-width:425px){

.logoContainer svg {
  width: 80px;
}
.logoContainer{
  margin-top: 5%;
  margin-bottom: 10%;
}
}


@media (max-width: 650px){
  .fullDialog .MuiDialog-paper{
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 100%;
    max-height: none;
    border-radius: 0;
  }
 
  #billingForm button {
    position: relative;
    bottom: 0;
    left: 0;
    margin: 10px auto;
    width: 50%;
    display: block;
  }
  .fullDialog.grayBg .MuiDialog-paper{
    background-color: #F5F5F7;
  }
  .productImage { display: none;}
 
  .productProgress span {
    display: none;
  }
  .productProgress {
    width: 90%;
    padding-right: 10px;
  }
  .productDots { display: none;}
  #productsDashboard li, #fulfilledDashboard li, #productsReviewDashboard li {
    flex-direction: column;
    padding-bottom: 15%;
  }
  .productInfo p.account {
    position: absolute;
}
#logoadmin img { width: 100%;}
div#product-specs p {
  width: 100%;
}
div#product-specs div {
  padding-bottom: 10px;
}
.helpmeSource { min-width: 100%;}
#accountModal .MuiPaper-root.MuiDialog-paperWidthMd, 
#taskDetailsMOdal .MuiPaper-root.MuiDialog-paperWidthMd,
#flowsStartModals  .MuiPaper-root.MuiDialog-paperWidthMd,
#billing  .MuiPaper-root.MuiDialog-paperWidthMd, 
#forgotPassword .MuiPaper-root.MuiDialog-paperWidthMd,
#resetPassword .MuiPaper-root.MuiDialog-paperWidthMd ,
#newTaskModal .MuiPaper-root.MuiDialog-paperWidthMd,
#editTaskModal .MuiPaper-root.MuiDialog-paperWidthMd{
  width: 100%;
}
  }
 

/* XS Breakpoint*/
@media (max-width:599px){
  .notification-header { order: 2;}
  .admin-header {
    order: 1;
    width: 90%;
}
  #userMenuButton { order: 3;}
  .tooltiptext { width: 60px; }
  .headerBg { 
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    z-index: 3;
  }
  .tasksDiv { border-right: none;}
  .task-sidebar { margin-left: 5%;}
  ul.taskList {
    border-top: none;
    height: auto;
  }
  .searchTasks {
    width: 100%;
  }
  li.notificationText { width: 100% !important; min-height: 95px;}
  
  .productInfo p:first-child{
    font-weight: bold;
  }
  #adminTabs button span.MuiTab-wrapper {  font-size: 0.8em !important; }
  #productDetailsTargets, #warehouseDetails {
    justify-content: space-between;
    flex-direction: column; 
}
#lifecycleBar div.lifecycleInfo.flex {
  width:50px;
  padding: 0;
  min-height: 65px;
}
.lifecycleInfo span{ order:1;}
.lifecycleInfo p {
  font-size: 9px;
  order:2;
}
.lifecycleInfo.workSans div {
  order:3;
}
label.MuiFormControlLabel-root.warehouseRadio {
  display: block;
  width: 100%;
  margin: 2% 0;
}
.split-form {
  flex-direction: column;
}
.bills div.yellowSubmit.nextArrow input { 
  background-position-x: 90%;
}
div#targetPriceForm{
  width: 100%;
  margin-right: 0;
}
div.loginForm .todosItems.loginInput {
  width: 77%;
}
.m-b-2.todosItemsText p {
  max-width: 70%;
}
.nonEditableInfo.m-t-2 {
  width: 100%;
}
#billingTitles, #billingInputs { width: 50%; display: inline-block;}
div.billingColumn {
  width: 100%;
  height: 55px;
}
div.billingColumnSM {
width: 100%;
height: 55px;
}
div#billingValues {
  width: 50%;
  display: inline-block;
}
}